import { Tabs } from "../components/sites/Project/Project";
import { ProjectHeaderState } from "../reducers/projectHeaderReducer";
import { Period } from "./period";
import { ProjectFiltersData } from "../components/sites/Projects/FiltersConfiguration/FiltersConfiguration";
import { ColumnId, ColumnSetValue, ColumnSetState } from "./columnsTypes";
import {
  RateCurrencyPair,
  RecognitionCellAdditionalInfo,
  RecognitionsEditState,
  UserPreferencesState,
} from "../components/sites/Project/ProjectRecognitions/types";
import {
  ProfitPITProgressId,
  ProfitStatusId,
} from "../components/sites/Project/ProjectBasics/IntegrationsSection/types";

export interface AppState {
  authState: AuthState;
  filtersState: FiltersState;
  historyState: HistoryState;
  columnSetState: ColumnSetState;
  settingsState: SettingsState;
  projectState: ProjectState;
  recognitionEditState: RecognitionsEditState;
  hierarchyState: HierarchyState;
  costsState: CostsState;
  projectHeaderState: ProjectHeaderState;
  userPreferencesState: UserPreferencesState;
}

export interface AuthState {
  authenticated: boolean;
  user?: User;
  error: unknown;
}

export enum HierarchyItemEnum {
  Node = "Node",
  Project = "Project",
  NewProject = "NewProject",
}

export enum EntityTypeId {
  ProjectWithRelations = 1,
  ProjectWithoutRelations = 2,
  ProjectAdjustment = 3,
  ManualAdjustment = 4,
  ServiceProject = 5,
  AggregatedOrders = 6,
  AutomaticAdjustment = 7,
}

export enum ProjectTypes {
  Actual = 1,
  Estimate = 2,
  Budget = 3,
  PT = 4,
  AutPT = 5,
  CostPlus = 6,
}

export type HierarchyItemId = number;
export type ProjectTypeId = number;
export type ISOLocalDateTime = string; // ISO format, no timezone, UTC
export type ISOTimestamp = string; // ISO format with Z timezone (UTC)
export type ProjectStatusId = number;
export type ProjectPhaseId = number;
type CustomerId = number;
type CountryId = number;
type AutReportingCountryId = number;
export type OrganisationKeyId = number;
export type OrganisationCode = string;
type FinancingMethod = string;
type PerformanceObligation = string;
type ProjectRelationId = number;
export type BasicDataCurrency = string;
export type CompanyCode = number;
export type NodeId = number;
export type CommentType = string;
type EstimateCodeId = string;
export type ProjectId = number;
export type ActivityId = string;
type CostComponentId = string;
type CurrencyId = number;
export type ProjectRelationType = string;
export type CommentId = string;
export type DeliveryLocationId = number;
export type NumberRange = {
  min: number | undefined;
  max: number | undefined;
};
export type ProjectFiltersId = string;

export type UserOverridable<T> = {
  effective: T | null;
  cached: T | null;
};

export interface ProjectRelatingKey {
  desc: string;
  kind:
    | "BaanProject"
    | "BaanProjectPart"
    | "BaanPcsProject"
    | "BaanSoProject"
    | "BaanSoLine"
    | "LnProject"
    | "FreeText"
    | "Numbers";
}

export interface ProjectRelationTypeItem {
  relationType: ProjectRelationType;
  displayName: string;
  sourceSystem: SourceSystem;
  selectable: boolean;
  relatingKeys: ProjectRelatingKey[];
}

export interface Action {
  type: string;
  data?: User;
}

export interface ListingNode {
  nodeId: number;
  description: string;
  childProjects: ProjectData[];
  childNodes: ListingNode[];
  columns: ListingProjectCell[];
  childLevel?: number; // used for rendering
  key?: string; // used for rendering
  __typename: string;
}

export interface ProjectData {
  projectId: ProjectId;
  columns: ListingProjectCell[];
  childLevel?: number; // used for rendering
  key?: string; // used for rendering
  __typename: string;
}

export interface ListingProjectCell {
  type: "ListingProjectCell";
  columnId: ColumnId;
  value: ColumnSetValue;
  comments: ListingProjectCellComment[];
  freezings: ListingProjectCellFreezing[];
  additionalInfo: RecognitionCellAdditionalInfo | null;
}

export interface Comment {
  type: CommentType;
  value?: number;
  content: string;
  currencyScenario: string;
  createdBy: string;
  createdDateTime: string;
}

export interface ListingProjectCellComment {
  fieldName: string;
  comments: Comment[];
}

export interface ListingProjectCellFreezing {
  fieldName: string;
  value: number;
  createdBy: string;
  createdDateTime: string;
}

export interface ProjectListData {
  projects: {
    nodes: ListingNode[];
    availableResults: number;
  };
}

export interface ProjectStatus {
  id: number;
  description: string;
}

export interface ProjectPhase {
  id: number;
  description: string;
}

export interface ProjectPhasesResult {
  projectPhases: ProjectPhase[];
}

export interface ProjectStatusesResult {
  projectStatuses: ProjectStatus[];
}

export interface ProjectType {
  id: number;
  description: string;
}

export interface ProjectTechnicalType {
  id: number;
  description: string;
}

export interface ProjectTechnicalTypesResult {
  projectTechnicalTypes: ProjectTechnicalType[];
}

export interface ProjectTypesResult {
  projectTypes: ProjectType[];
}

export interface DeliveryLocation {
  id: DeliveryLocationId;
  desc: string;
  code: string;
  description: string;
  childItems: DeliveryLocation[] | undefined;
}

export interface ProjectDeliveryLocationsResult {
  deliveryLocations: DeliveryLocation[];
}

export enum FilterEnum {
  hierarchy,
  projectPhase,
  technicalType,
  projectType,
  projectStatus,
  businessGroup,
  businessType,
  person,
  project,
  projectPeriod,
  oblPeriod,
  customer,
  deliveryOrg,
  endDestCountry,
  financingMethod,
  legalEntity,
  extLegalEntity,
  estSalesPrice,
}

export type FilterId = keyof typeof FilterEnum;

export interface FilterSetting {
  id: FilterId;
  name: string;
  values: FilterOption[] | FilterHierarchyOption[];
  type: FilterType;
}

export interface FilterOption {
  id: number | string;
  description: string;
}

export interface FilterHierarchyOption {
  id: number | string;
  description: string;
  children: FilterHierarchyOption[];
}

export interface FilterAction {
  type: string;
  data?: {
    id: FilterId;
    value: string | number | NumberRange | undefined;
  };
  selectedFilters?: ProjectFiltersData;
}

export interface FilterSelections {
  GENERIC: FilterSetting[];
  TIME: FilterSetting[];
  ADDITIONAL: FilterSetting[];
  REC: FilterSetting[];
}

export enum FilterType {
  NUMBER_ARRAY = "numberArray",
  NUMBER = "number",
  STRING = "string",
  NUMBER_RANGE = "numberRange",
}

export interface FilterValues {
  hierarchy?: number[];
  projectPhase?: number[];
  technicalType?: number[];
  projectType?: number[];
  projectStatus?: number;
  businessGroup?: number[];
  businessType?: number[];
  person?: string;
  project?: string;
  projectPeriod?: string;
  oblPeriod?: string;
  customer?: number[];
  deliveryOrg?: number[];
  endDestCountry?: number[];
  financingMethod?: number[];
  legalEntity?: number[];
  extLegalEntity?: number[];
  estSalesPrice?: NumberRange;
}

export interface FilterInput {
  hierarchy?: number[];
  projectPhase?: number[];
  technicalType?: number[];
  projectType?: number[];
  projectStatus?: number;
  businessGroup?: number[];
  businessType?: number[];
  person?: string;
  project?: string;
  projectPeriod?: string;
  oblPeriod?: string;
  customer?: number[];
  deliveryOrg?: number[];
  endDestCountry?: number[];
  financingMethod?: number[];
  legalEntity?: number[];
  extLegalEntity?: number[];
  estSalesPrice?: NumberRange;
}

export interface FiltersState {
  selectedFiltersId: ProjectFiltersId | undefined;
  selectedFiltersName: string;
  filters: FilterValues;
  filtersPristine: boolean;
}

export interface ProjectHistory {
  id: number;
  name: string;
}

export interface HistoryProjectAction {
  type: string;
  data: {
    id: number;
    name: string;
  };
}

export interface HistoryViewAction {
  type: string;
}

export interface HistoryTabSelectedAction {
  type: string;
  tab: Tabs;
  itemType: HierarchyItemEnum;
}

export type HistoryAction = HistoryProjectAction | HistoryViewAction | HistoryTabSelectedAction;

export interface HistoryState {
  projectHistory: ProjectHistory[];
  historyViewOpen: boolean;
}

export interface SettingsStateAction {
  type: string;
  selectedCurrency?: Currency;
}

export interface SettingsState {
  selectedCurrency?: Currency;
}

export interface ProjectCostValues {
  asSold: number | null;
  originalBudget: number | null;
  proposedBudgetChange: number | null;
  revisedBudget: number | null;
  calculatedEstimate: number | null;
  prevEstimate: number | null; // Displayed in PREVIOUS EST. column, this value changes as estimateCode selection changes
  estimateChange: number | null;
  currentEstimate: number | null;
  lastEstimate: number | null;
  ETC: number | null;
  committed: number | null;
  actuals: number | null;
  hardCommitments: number | null;
  wipCosts: number | null;
  wipCostsCumulative: number | null;
  periodicWipCosts: number | null; // calculated field, updated based on selected periods
  softCommitments: number | null;
  estimatedHours: number | null;
  actualHours: number | null;
  defaultPrevEstimate: number | null; // This value does not change as selection changes, and is always the current estimate value of the actual previous estimate code
}

type ProjectCostValuesKey = keyof ProjectCostValues;

export type ProjectCostColumnId = ProjectCostValuesKey | ProjectCostEditColumnId;

export interface ActivityTooltip {
  isBlocked: boolean;
  coldLinesExist: boolean;
}

export interface ProjectCostItem {
  id: string;
  projectRelatingKey1: string | null;
  description: string;
  currencyId: CurrencyId;
  estimateCodeId: EstimateCodeId;
  values: ProjectCostValues;
  currencyCode: string;
  currencyRate?: number;
  childLevel?: number;
  childItems?: ProjectCostItem[];
  hasWarrantyOrContingencyComponents: boolean;
  tooltip: ActivityTooltip | null;
}

export interface ProjectCostComponentItems {
  id: string;
  description: string;
  values: ProjectCostValues;
  components: ProjectCostComponentItem[];
  isEditingBlocked: boolean;
  currencyRate: number;
}

export interface ProjectCostComponentItem {
  id: string;
  activityId: string;
  description: string;
  values: ProjectCostValues;
}

class EstimateCommentTypeBrand {
  private __brand = "estimatecommenttype_brand";
}
export type ActivityEstimateCommentTypeId = string & EstimateCommentTypeBrand;
const validateEstimateCommentType = (id: string): id is ActivityEstimateCommentTypeId => true;
export const toEstimateCommentTypeId = (id: string): ActivityEstimateCommentTypeId => {
  if (validateEstimateCommentType(id)) {
    return id;
  } else {
    throw Error("not an activity estimate comment type ID");
  }
};

export interface ProjectCostActivityComment {
  commentId: string;
  commentType: ActivityEstimateCommentTypeId;
  commentText: string;
  originalText?: string;
  modifiedDate: ISOTimestamp;
  modifiedBy: string;
}

export interface ProjectCostCommentItem {
  activityId: ActivityId;
  comments: ProjectCostActivityComment[];
}

export interface CostComponentSaveResult {
  success: boolean;
  activityIdsForUpdatedEstimates: ActivityId[];
  applicationModifiedDateTime: string | null;
  messages: string[];
}

export interface ProjectOrganisation {
  businessGroup: string | null;
  businessType: string | null;
  legalEntity: string | null;
  intExt: string | null;
  corporation: string | null;
  customerId: CustomerId | null;
  customerName: string | null;
  industry: string | null;
  autSiteCode: string | null;
  icpTradingPartner: string | null;
}

export interface ExternalOrganisation {
  businessGroup: string | null;
  businessType: string | null;
  legalEntity: string | null;
  reporting: string | null;
  customerId: CustomerId | null;
  customerName: string | null;
  externalReportingIndustry: string | null;
  orderValueIncludedInRelated: boolean | null;
}

export interface ContractOwner {
  businessGroup: string | null;
  businessType: string | null;
  legalEntity: string | null;
}

export interface ProjectInformation {
  projectKey: string | null;
  mainProjectKey: string | null;
  name: string | null;
  description: string | null;
  projectStatus: string | null;
  projectCardStatus: string | null;
  projectType: string | null;
  projectPhase: string | null;
  projectTechnicalType: string | null;
  organisation: ProjectOrganisation;
  contractOwner: ContractOwner;
  EDC: string | null;
  autReportingCountry: string | null;
  deliveryOrg: string | null;
  deliveryLocation: string | null;
  projectOffice: string | null;
  financingMethod: string | null;
  performanceObligation: string | null;
  projectVirtualType: ProjectVirtualType;
}

export interface ProjectDates {
  [key: string]: string | boolean | undefined;

  OBLPeriod?: string;
  asSoldPeriod?: string;
  budgetPeriod?: string;
  recPlanStartPeriod?: string;
  recPlanEndPeriod?: string;
  recCompletionPeriod?: string;
  projectClosingPeriodInERP?: string;
  startUpPeriod?: string;
  warrantyEndPeriod?: string;
  warrantyStartPeriod?: string;
  contractEffectivePeriod?: string;
  contractExpiryPeriod?: string;
  migrationPeriod?: string;
  financeClosingPeriod?: string;
}

export interface ProjectCurrency {
  contractCurrency: BasicDataCurrency | null;
  legalEntityCurrency: BasicDataCurrency | null;
  projectCurrency: BasicDataCurrency;
  revenueRecognitionCurrency: BasicDataCurrency | null;
  externalReportingCurrency: BasicDataCurrency | null;
  internalReportingCurrency: BasicDataCurrency | null;
}

export interface ProjectRelation {
  projectRelationId: ProjectRelationId;
  relationType: ProjectRelationType;
  company: number;
  relatingKeys: string[];
  erpLinkStatus: string | null;
}

export interface ProjectPerson {
  userId: UserId;
  firstName: string;
  lastName: string;
}

export interface ProjectRoles {
  [key: string]: ProjectPerson | null;

  mainProjectManager: ProjectPerson | null;
  mainProjectController: ProjectPerson | null;
  projectController: ProjectPerson | null;
  projectManager: ProjectPerson | null;
  projectManagersManager: ProjectPerson | null;
  projectCoordinator: ProjectPerson | null;
}

export type UserId = number;

export interface ProjectRolesSectionInput {
  input: ProjectRolesInput;
  pristine: boolean;
}

export interface ProjectRolesInput {
  mainProjectManagerId: UserId | null;
  mainProjectControllerId: UserId | null;
  projectControllerId: UserId | null;
  projectManagerId: UserId | null;
  projectManagersManagerId: UserId | null;
  projectCoordinatorId: UserId | null;
}

export interface ProjectRolesSaveResult {
  result: ProjectRoles;
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface ProjectRolesEditInformation {
  mainProjectManagerProperties: FieldProperties;
  mainProjectControllerProperties: FieldProperties;
  projectControllerProperties: FieldProperties;
  projectManagerProperties: FieldProperties;
  projectManagersManagerProperties: FieldProperties;
  projectCoordinatorProperties: FieldProperties;
}

export interface ProjectRolesEditInformationData {
  projectDetailsEditInformation: {
    projectRoles: ProjectRolesEditInformation;
  };
}

export interface ProjectDetailsEditable {
  information: boolean;
  dates: boolean;
  currency: boolean;
  roles: boolean;
  relations: boolean;
  tags: boolean;
  integrations: boolean;
  hierarchy: boolean;
  reportingRelations: boolean;
}

export interface ProjectDetails {
  projectId: ProjectId;
  projectInformation: ProjectInformation;
  projectDates?: ProjectDates;
  projectCurrency?: ProjectCurrency;
  projectRoles: ProjectRoles;
  relations: ProjectRelation[];
  projectTags: ProjectTag[];
  integrations: ProjectIntegrations;
  reportingRelations: ProjectReportingRelations;
  editable: ProjectDetailsEditable;
}

export interface ProjectInformationVisibility {
  name: boolean;
  description: boolean;
  projectKey: boolean;
  mainProjectKey: boolean;
  projectPhase: boolean;
  projectTechnicalType: boolean;
  projectStatus: boolean;
  projectCardStatus: boolean;
  projectType: boolean;
  EDC: boolean;
  deliveryOrg: boolean;
  deliveryLocation: boolean;
  projectOffice: boolean;
  financingMethod: boolean;
  performanceObligation: boolean;
  autReportingCountry: boolean;
  organisation: ProjectOrganisationVisibility | null;
  contractOwner: ContractOwnerVisibility | null;
}

export interface ProjectOrganisationVisibility {
  businessGroup: boolean;
  businessType: boolean;
  legalEntity: boolean;
  intExt: boolean;
  corporation: boolean;
  customerId: boolean;
  customerName: boolean;
  industry: boolean;
  autSiteCode: boolean;
  icpTradingPartner: boolean;
}

export interface ExternalOrganisationVisibility {
  businessGroup: boolean;
  businessType: boolean;
  legalEntity: boolean;
  reporting: boolean;
  customerId: boolean;
  customerName: boolean;
  externalReportingIndustry: boolean;
  orderValueIncludedInRelated: boolean;
}

export interface ContractOwnerVisibility {
  businessGroup: boolean;
  businessType: boolean;
  legalEntity: boolean;
}

export interface ProjectRolesVisibility {
  mainProjectManager: boolean;
  mainProjectController: boolean;
  projectController: boolean;
  projectManager: boolean;
  projectManagersManager: boolean;
  projectCoordinator: boolean;
}

export interface ProjectIntegrationsVisibility {
  profitStatusId: boolean;
  profitPITProgressId: boolean;
}

export interface ProjectCurrencyVisibility {
  contractCurrency: boolean;
  legalEntityCurrency: boolean;
  projectCurrency: boolean;
  revenueRecognitionCurrency: boolean;
  externalReportingCurrency: boolean;
  internalReportingCurrency: boolean;
}

export interface ProjectDatesVisibility {
  OBLPeriod: boolean;
  asSoldPeriod: boolean;
  budgetPeriod: boolean;
  recPlanStartPeriod: boolean;
  recPlanEndPeriod: boolean;
  recCompletionPeriod: boolean;
  startUpPeriod: boolean;
  projectClosingPeriodInERP: boolean;
  warrantyEndPeriod: boolean;
  warrantyStartPeriod: boolean;
  contractEffectivePeriod: boolean;
  contractExpiryPeriod: boolean;
  migrationPeriod: boolean;
  financeClosingPeriod: boolean;
}

export interface ProjectReportingRelations {
  relatingProjectId: ProjectId | null;
  relatingProjectDesc: string | null;
  externalOrganisation: ExternalOrganisation;
  serMarginTraceability: boolean | null;
  relatedLegalCompany: string | null;
}

export interface ProjectReportingRelationsVisibility {
  relatingProjectId: boolean;
  relatingProjectDesc: boolean;
  externalOrganisation: ExternalOrganisationVisibility | null;
  serMarginTraceability: boolean;
  relatedLegalCompany: boolean;
}

export interface ProjectReportingRelationsEditInformation {
  externalOrganisation: ExternalOrganisationEditInformation;
  serMarginTraceabilityProperties: FieldProperties;
}

export interface ProjectDetailsVisibility {
  projectInformation: ProjectInformationVisibility | null;
  projectDates: ProjectDatesVisibility | null;
  projectCurrency: ProjectCurrencyVisibility | null;
  projectRoles: ProjectRolesVisibility | null;
  projectRelations: boolean;
  projectTags: boolean;
  integrations: ProjectIntegrationsVisibility | null;
  reportingRelations: ProjectReportingRelationsVisibility | null;
}

export interface ProjectDetailsResult {
  projectDetails: ProjectDetails;
  projectDetailsVisibility: ProjectDetailsVisibility;
}

export interface ProjectDetailsData {
  projectDetails: ProjectDetailsResult;
}

export interface ProjectOrganisationEditInformation {
  businessGroupId: UserOverridable<OrganisationKeyId>;
  businessGroupProperties: FieldProperties;
  businessTypeId: UserOverridable<OrganisationKeyId>;
  businessTypeProperties: FieldProperties;
  legalEntityId: UserOverridable<OrganisationKeyId>;
  legalEntityProperties: FieldProperties;
  intExtId: OrganisationKeyId | null;
  intExtProperties: FieldProperties;
  customerId: CustomerId | null;
  customerProperties: FieldProperties;
  industryId: OrganisationKeyId | null;
  industryProperties: FieldProperties;
  autSiteCodeId: OrganisationKeyId | null;
  autSiteCodeProperties: FieldProperties;
  icpTradingPartnerId: OrganisationKeyId | null;
  icpTradingPartnerProperties: FieldProperties;
}

export interface ExternalOrganisationEditInformation {
  businessGroupId: OrganisationKeyId | null;
  businessGroupProperties: FieldProperties;
  businessTypeId: OrganisationKeyId | null;
  businessTypeProperties: FieldProperties;
  legalEntityId: OrganisationKeyId | null;
  legalEntityProperties: FieldProperties;
  reportingId: OrganisationKeyId | null;
  reportingProperties: FieldProperties;
  customerId: CustomerId | null;
  customerProperties: FieldProperties;
  externalReportingIndustryId: OrganisationKeyId | null;
  externalReportingIndustryProperties: FieldProperties;
  orderValueIncludedInRelated: boolean | null;
  orderValueIncludedInRelatedProperties: FieldProperties;
}

export interface ContractOwnerEditInformation {
  businessGroupId: UserOverridable<OrganisationKeyId>;
  businessGroupProperties: FieldProperties;
  businessTypeId: OrganisationKeyId | null;
  businessTypeProperties: FieldProperties;
  legalEntityId: OrganisationKeyId | null;
  legalEntityProperties: FieldProperties;
}

export type FreetextProjectDescription = {
  __typename: "FreetextProjectDescription";
  desc: string;
};

export type CompositeProjectDescription = {
  __typename: "CompositeProjectDescription";
  stem: string;
  postfix: string;
};

export type ProjectDescriptionEditInformation = FreetextProjectDescription | CompositeProjectDescription;

export interface ProjectInformationEditInformation {
  projectKeyProperties: FieldProperties;
  mainProjectKeyProperties: FieldProperties;
  projectNameProperties: FieldProperties;
  projectDescription: ProjectDescriptionEditInformation;
  projectDescriptionProperties: FieldProperties;
  projectPhaseId: ProjectPhaseId | null;
  projectPhaseProperties: FieldProperties;
  projectTechnicalTypeId: EntityTypeId | null;
  projectTechnicalTypeProperties: FieldProperties;
  projectStatusId: ProjectStatusId | null;
  projectStatusProperties: FieldProperties;
  projectTypeId: UserOverridable<ProjectTypeId>;
  projectTypeProperties: FieldProperties;
  organisation: ProjectOrganisationEditInformation;
  contractOwner: ContractOwnerEditInformation;
  EDCId: CountryId | null;
  EDCProperties: FieldProperties;
  autReportingCountryId: CountryId;
  autReportingCountryProperties: FieldProperties;
  deliveryOrgId: OrganisationKeyId | null;
  deliveryOrgProperties: FieldProperties;
  deliveryLocationId: DeliveryLocationId | null;
  deliveryLocationProperties: FieldProperties;
  financingMethodProperties: FieldProperties;
  performanceObligationProperties: FieldProperties;
}

export interface ProjectInformationEditInformationData {
  projectDetailsEditInformation: {
    projectInformation: ProjectInformationEditInformation;
  };
}

export interface ProjectReportingRelationsEditInformationData {
  projectDetailsEditInformation: {
    reportingRelations: ProjectReportingRelationsEditInformation;
  };
}

export interface ProjectDatesSectionInput {
  input: ProjectDatesInput;
  pristine: boolean;
}

export interface ProjectDatesInput {
  OBLPeriod: string | null;
  asSoldPeriod: string | null;
  budgetPeriod: string | null;
  recPlanStartPeriod: string | null;
  recPlanEndPeriod: string | null;
  recCompletionPeriod: string | null;
  startUpPeriod: string | null;
  projectClosingPeriodInERP: string | null;
  warrantyStartPeriod: string | null;
  warrantyEndPeriod: string | null;
  contractEffectivePeriod: string | null;
  contractExpiryPeriod: string | null;
  migrationPeriod: string | null;
  financeClosingPeriod: string | null;
}

export interface ProjectDatesSaveResult {
  result: ProjectDates;
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface ProjectCurrencySectionInput {
  input: ProjectCurrencyInput;
  pristine: boolean;
}

export interface ProjectCurrencyInput {
  contractCurrency: BasicDataCurrency | null;
  legalEntityCurrency: BasicDataCurrency | null;
  projectCurrency: BasicDataCurrency | null;
  revenueRecognitionCurrency: BasicDataCurrency | null;
  externalReportingCurrency: BasicDataCurrency | null;
  internalReportingCurrency: BasicDataCurrency | null;
}

export interface ProjectCurrencySaveResult {
  result: ProjectCurrency;
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface ProjectOrganisationInput {
  businessGroupId: OrganisationKeyId | null;
  businessTypeId: OrganisationKeyId | null;
  legalEntityId: OrganisationKeyId | null;
  intExtId: OrganisationKeyId | null;
  customerId: CustomerId | null;
  industryId: OrganisationKeyId | null;
  autSiteCodeId: OrganisationKeyId | null;
  icpTradingPartnerId: OrganisationKeyId | null;
}

export interface ExternalOrganisationInput {
  businessGroupId: OrganisationKeyId | null;
  businessTypeId: OrganisationKeyId | null;
  legalEntityId: OrganisationKeyId | null;
  reportingId: OrganisationKeyId | null;
  customerId: CustomerId | null;
  externalReportingIndustryId: OrganisationKeyId | null;
  orderValueIncludedInRelated: boolean | null;
}

export interface ContractOwnerInput {
  businessGroupId: OrganisationKeyId | null;
  businessTypeId: OrganisationKeyId | null;
  legalEntityId: OrganisationKeyId | null;
}

export interface ProjectInformationSectionInput {
  input: ProjectInformationInput;
  pristine: boolean;
  inherited: ProjectInformationInherited | undefined;
}

export interface ProjectInformationInherited {
  contractOwner: ContractOwnerInherited;
}

interface ExternalOrganisationInherited {
  businessGroupId: boolean;
  businessTypeId: boolean;
  legalEntityId: boolean;
  externalReportingIndustryId: boolean;
}

interface ContractOwnerInherited {
  businessGroupId: boolean;
}

export interface ProjectInformationInput {
  projectKey: string | null;
  mainProjectKey: string | null;
  projectName: string | null;
  projectDescription: string | null;
  projectPhaseId: ProjectPhaseId | null;
  projectStatusId: ProjectStatusId | null;
  projectTechnicalTypeId: EntityTypeId | null;
  projectTypeId: ProjectTypeId | null;
  organisation: ProjectOrganisationInput;
  contractOwner: ContractOwnerInput;
  EDCId: CountryId | null;
  autReportingCountryId: CountryId | null;
  deliveryOrgId: OrganisationKeyId | null;
  deliveryLocationId: DeliveryLocationId | null;
  financingMethod: FinancingMethod | null;
  performanceObligation: PerformanceObligation | null;
}

export interface ProjectInformationSaveResult {
  result: ProjectInformation;
  hierarchyModifiedDateTime: ISOTimestamp | null;
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface ProjectRelationSectionInput {
  input: ProjectRelationInput[];
  pristine: boolean;
}

export interface ProjectRelationInput {
  projectRelationId: ProjectRelationId | null;
  relationType: ProjectRelationType | null;
  company: CompanyCode | null;
  relatingKeys: string[];
}

export interface ProjectRelationSaveResult {
  result: ProjectRelation[];
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface ProjectInput {
  projectInformation: ProjectInformationSectionInput;
  projectDates: ProjectDatesSectionInput;
  projectCurrency: ProjectCurrencySectionInput;
  projectRelations: ProjectRelationSectionInput;
  projectRoles: ProjectRolesSectionInput;
  projectTags: ProjectTagsSectionInput;
  projectIntegrations: ProjectIntegrationsSectionInput;
  reportingRelations: ReportingRelationsSectionInput;
}

export type ProjectInformationSaveResponse = {
  result: ProjectInformation | null;
  visibility: ProjectDetailsVisibility | null;
  applicationModifiedDateTime: ISOLocalDateTime | null;
  hierarchyModifiedDateTime: ISOLocalDateTime | null;
  errors: string[] | null;
};

export interface FieldProperties {
  editable: boolean;
  nullable: boolean | null;
  message?: string | null;
  shouldInherit?: boolean;
}

export interface ProjectDatesEditInformation {
  OBLPeriodProperties: FieldProperties;
  asSoldPeriodProperties: FieldProperties;
  budgetPeriodProperties: FieldProperties;
  recPlanStartPeriodProperties: FieldProperties;
  recPlanEndPeriod: UserOverridable<Period>;
  recPlanEndPeriodProperties: FieldProperties;
  recCompletionPeriodProperties: FieldProperties;
  startUpPeriodProperties: FieldProperties;
  projectClosingPeriodInERPProperties: FieldProperties;
  warrantyStartPeriodProperties: FieldProperties;
  warrantyEndPeriodProperties: FieldProperties;
  contractEffectivePeriodProperties: FieldProperties;
  contractExpiryPeriodProperties: FieldProperties;
  migrationPeriodProperties: FieldProperties;
  financeClosingPeriodProperties: FieldProperties;
}

export interface ProjectDatesEditInformationData {
  projectDetailsEditInformation: {
    projectDates: ProjectDatesEditInformation;
  };
}

export interface ProjectCurrencyEditInformation {
  contractCurrencyProperties: FieldProperties;
  legalEntityCurrencyProperties: FieldProperties;
  projectCurrencyProperties: FieldProperties;
  revenueRecognitionCurrencyProperties: FieldProperties;
  externalReportingCurrencyProperties: FieldProperties;
  internalReportingCurrencyProperties: FieldProperties;
}

export interface ProjectTagsEditInformation {
  fixedTags: ProjectTag[];
  editableTags: ProjectTag[];
  allSelectableTags: ProjectTag[];
}

export interface ProjectCurrencyEditInformationData {
  projectDetailsEditInformation: {
    projectCurrency: ProjectCurrencyEditInformation;
  };
}

export interface ProjectRelationsEditInformation {
  values: ProjectRelation[];
  properties: FieldProperties;
}

export interface ProjectRelationEditInformationData {
  projectDetailsEditInformation: {
    relations: ProjectRelationsEditInformation;
  };
}

export interface ProjectTagsEditInformationData {
  projectDetailsEditInformation: {
    tags: ProjectTagsEditInformation;
  };
}

export interface ProjectDetailsEditInformation {
  projectInformation: ProjectInformationEditInformation | undefined;
  projectDates: ProjectDatesEditInformation | undefined;
  projectCurrency: ProjectCurrencyEditInformation | undefined;
  relations: ProjectRelationsEditInformation | undefined;
  projectRoles: ProjectRolesEditInformation | undefined;
  integrations: ProjectIntegrationsEditInformation | undefined;
  reportingRelations: ProjectReportingRelationsEditInformation | undefined;
}

export interface PITProgressLog {
  percentage: string;
  profitPITProgressId: ProfitPITProgressId;
  modifiedAt: ISOTimestamp;
}

export interface ProjectIntegrations {
  isInRadar: boolean;
  sendToRadar: boolean;
  radarUrl: string | null;
  profitStatusId: ProfitStatusId | null;
  profitPITProgressId: ProfitPITProgressId | null;
  profitPITProgressPercentage: string | null;
  profitPITProgressLog: PITProgressLog[] | null;
}

export interface ProjectIntegrationsEditInformation {
  sendToRadar: FieldProperties;
  profitStatusId: FieldProperties;
  profitPITProgressId: FieldProperties;
  profitPITProgressPercentage: number;
}

export type InputValue<T> = T | null;

export interface ProjectIntegrationsInput {
  sendToRadar: InputValue<boolean>;
  profitStatusId: InputValue<ProfitStatusId>;
  profitPITProgressId: InputValue<ProfitPITProgressId>;
  profitRecPercentage: InputValue<number>;
}

export interface ProjectIntegrationsSectionInput {
  input: ProjectIntegrationsInput;
  pristine: boolean;
}

export interface ProjectIntegrationsSaveResult {
  result: ProjectIntegrations | null;
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface ProjectReportingRelationsInput {
  externalOrganisation: ExternalOrganisationInput;
  serMarginTraceability: boolean | null;
}

export interface ReportingRelationsSectionInput {
  input: ProjectReportingRelationsInput;
  pristine: boolean;
}

export interface ProjectReportingRelationsSaveResult {
  result: ProjectReportingRelations | null;
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface AdjustmentRateInfo {
  currencyPairs: RateCurrencyPair[];
  editable: boolean;
}

export interface HierarchyItem {
  id: HierarchyItemId;
  description: string;
  active: boolean;
  itemType: HierarchyItemEnum;
  childItems: HierarchyItem[];
  childLevel?: number;
  userAdded?: boolean;
  mainProjectId: ProjectId | null;
  projectTechnicalTypeId: EntityTypeId | null;
  projectTypeId: ProjectTypeId | null;
  hiddenInHierarchyView: boolean; // if true, the project or node should not be shown in the basic data hierarchy
  relatingProjectId: ProjectId | null;
  adjustmentRateInfo: AdjustmentRateInfo | null;
  __typename?: string;
}

export interface ProjectHeaderData {
  itemType: HierarchyItemEnum.Project;
  id: HierarchyItemId;
  description: string;
  hierarchy: HierarchyItem;
  projectTechnicalTypeId: EntityTypeId; // defined, if item type is project
  projectVirtualType: ProjectVirtualType; // defined, if item type is project
  projectPhaseId: ProjectPhaseId; // defined, if item type is project
  projectStatusId: ProjectStatusId; // defined, if item type is project
  legalEntityCurrency: string | null;
}

export interface NodeHeaderData {
  itemType: HierarchyItemEnum.Node;
  id: HierarchyItemId;
  description: string;
  hierarchy: HierarchyItem;
  mainProjectId: ProjectId | null;
}

export type HeaderData = ProjectHeaderData | NodeHeaderData;

export interface HeaderInfoData {
  headerInfo: HeaderData | null;
}

export interface ProjectHierarchyData {
  hierarchy: HierarchyItem;
}

export interface ProjectHierarchyInfoData {
  headerInfo: ProjectHierarchyData | null;
}

export interface ViewListItem {
  id: string;
  name: string;
  isGlobal: boolean;
}

export interface ViewsData {
  views: ViewListItem[];
}

export interface View {
  id: string;
  name: string;
  columns: ColumnId[];
  isGlobal: boolean;
}

export interface User {
  username: string;
  displayName: string;
  userId: string;
  isEditor: boolean;
  isAdmin: boolean;
  isProfitEditor: boolean | null;
}

export interface SearchProject {
  id: number;
  description: string;
}

export interface SearchProjectData {
  searchProjects: { projects: SearchProject[]; moreResultsAvailable: boolean };
}

export interface KeyValueItem {
  key: string;
  value: string;
}

export interface Currency {
  id: number;
  code: string;
  description: string;
}

export interface CurrenciesResult {
  currencies: Currency[];
}

export type ProjectListItem = ListingNode | ProjectData;

export interface PollReadyResult {
  ready: boolean;
}

export interface CommentInput {
  period: string;
  column: string;
  field: string;
  type: CommentType;
  value: number;
  content: string;
}

export const CommentTypes: { [index: string]: CommentType } = {
  FreezingComment: "Freezing comment",
};

export interface AddRecognitionPlanCommentResult {
  comment: Comment | null;
  errors: string[] | null;
}

export type SourceSystem = string;

export const SourceSystem = {
  Baan: "Baan",
  LN: "LN",
  SAP: "SAP",
  Lean: "Lean",
  Encompix: "Encompix",
};

export interface Company {
  code: CompanyCode;
  sourceSystem: SourceSystem;
}

export interface CompaniesResult {
  companies: Company[];
  baanPcsCompanies: Company[];
  baanSoCompanies: Company[];
}

export interface Country {
  id: CountryId;
  code: string;
  name: string;
}

export interface CountriesResult {
  countries: Country[];
}

export interface AutReportingCountry {
  id: AutReportingCountryId;
  code: string;
  name: string;
}

export interface AutReportingCountriesResult {
  autReportingCountries: AutReportingCountry[];
}

export interface Customer {
  id: CustomerId;
  name: string;
}

export interface CustomersResult {
  customers: Customer[];
}

export interface SearchCustomersResult {
  searchCustomers: { customers: Customer[]; availableResults: number };
}

export interface FinancingMethodsResult {
  financingMethods: FinancingMethod[];
}

export interface PerformanceObligationsResult {
  performanceObligations: PerformanceObligation[];
}

export interface OrganisationKey {
  id: OrganisationKeyId;
  code: OrganisationCode;
  description: string;
}

export interface OrganisationKeys {
  businessGroups: OrganisationKey[];
  businessTypes: OrganisationKey[];
  deliveryOrgs: OrganisationKey[];
  legalEntities: OrganisationKey[];
  intExts: OrganisationKey[];
  externalBusinessGroups: OrganisationKey[];
  externalBusinessTypes: OrganisationKey[];
  externalLegalEntities: OrganisationKey[];
  externalReportings: OrganisationKey[];
  contractOwnerBusinessGroups: OrganisationKey[];
  contractOwnerBusinessTypes: OrganisationKey[];
  contractOwnerLegalEntities: OrganisationKey[];
  industries: OrganisationKey[];
  icpTradingPartners: OrganisationKey[];
  autSites: OrganisationKey[];
  externalIndustries: OrganisationKey[];
}

export interface OrganisationKeysResult {
  organisationKeys: OrganisationKeys;
}

export interface OrganisationKeyHierarchy {
  id: OrganisationKeyId;
  code: string;
  description: string;
  children: OrganisationKeyHierarchy[];
}

export interface OrganisationKeyHierarchyResult {
  businessGroupHierarchies: OrganisationKeyHierarchy[];
}

export interface BasicDataCurrencyResult {
  basicDataCurrency: BasicDataCurrency[];
}

export interface ProjectErrors {
  [key: string]: boolean;

  project: boolean;
  date: boolean;
  currency: boolean;
  relations: boolean;
  roles: boolean;
}

export interface ProjectState {
  projectId: ProjectId | NodeId | undefined;
  projectCreationMode: boolean;
  projectTypeSelected: boolean;
  projectInput: ProjectInput | undefined;
  projectErrors: ProjectErrors;
  projectDetailsEditInformation: ProjectDetailsEditInformation | undefined;
  projectRelatingId: number | undefined;
  pendingChanges: string[];
  hierarchy: HierarchyItem | undefined;
  projectApplicationModifiedDateTime: string | undefined;
  nodeHierarchyApplicationModifiedDateTime: string | undefined;
  nodeHierarchyId: number | undefined;
}

export interface HierarchyState {
  parentNodeId: number | undefined | null;
  addedNodes: HierarchyTreeNode[];
  modifiedHierarchy: HierarchyItem | undefined;
  newNodes: NewNodeRequest[];
  movedNodes: NodeRequest[];
  movedProjects: ProjectRequest[];
  removedNodes: RemoveNodeRequest[];
  mainProjectChanges: MainProjectChangeRequest[];
}

export interface CreateNewProjectResult {
  projectId: ProjectId | undefined;
  applicationModifiedDateTime: string | undefined;
  errors: string[] | undefined;
}

export interface PersonsResult {
  persons: ProjectPerson[];
}

export interface LaborItem {
  id: string;
  description: string;
  values: string[];
  childLevel?: number;
  children: LaborItem[];
}

export interface MaterialsItem {
  id: string;
  description: string;
  values: string[];
}

export interface ProjectCosts {
  projectCostsSummary: ProjectCostItem[];
  projectCostsDetails: ProjectCostItem[];
}

export interface ProjectCostsResult {
  projectCosts: ProjectCostsAndEstimateTypes;
}

export interface ProjectCostLaborValues {
  [key: string]: string | number | null;

  transaction: string | null;
  budgetLabor: number | null;
  estimatedLabor: number | null;
  actualLabor: number | null;
  estimatedCost: number | null;
  actualCostEur: number | null;
}

export interface SaveNewProjectResult {
  data: {
    saveNewProject: {
      projectId: ProjectId;
      errors: string[] | null;
      applicationModifiedDateTime: string;
    };
  };
}

export interface SearchNode {
  id: NodeId;
  description: string;
  mainProjectId: ProjectId | null;
  mainProjectDescription: string | null;
}

export interface SearchNodeData {
  searchNodes: { nodes: SearchNode[]; moreResultsAvailable: boolean };
}

export interface GetNodeData {
  getNode: HierarchyItem;
}

export interface ContainedMainProject {
  parentDesc: string;
  id: ProjectId;
}

export interface HierarchyTreeItem {
  itemType: HierarchyItemEnum;
  id: ProjectId | NodeId;
  description: string;
  childLevel: number;
  connectionDown?: boolean;
  connectionUp?: boolean;
  isProject: boolean;
  isActive: boolean;
  extraConnections: number[];
  userAdded?: boolean;
  hasChildren: boolean;
  parentId?: NodeId;
  mainToNode?: ContainedMainProject[];
  hierarchyItem?: HierarchyItem;
  projectTechnicalTypeId: EntityTypeId | null;
  projectTypeId: ProjectTypeId | null;
}

export interface HierarchyTreeNode {
  parentNodeId?: NodeId;
  item: HierarchyTreeItem;
}

export interface ChangeNodesMainProjectsResult {
  successful: boolean;
}

export interface RelationProjectSearchResult {
  project: string;
  subProjects: string[];
  line: string | undefined;
}

export interface NewNodeRequest {
  tempId: NodeId;
  description: string;
  parentNodeId?: NodeId;
}

export interface PollReadyResult {
  ready: boolean;
}

export interface RemoveNodeResult {
  successful: boolean;
}

export interface NodeRequest {
  nodeId: NodeId;
  nodeDescription: string;
  parentNodeId?: NodeId;
}

export interface ProjectRequest {
  projectId: ProjectId;
  nodeId: NodeId;
}

export interface RemoveNodeRequest {
  nodeId: NodeId;
  description: string;
}

export interface MainProjectChangeRequest {
  mainProjectId: ProjectId | null;
  nodeId: NodeId;
}

export interface AddProjectToHierarchyRequest {
  projectId: ProjectId;
  nodeId: NodeId;
}

export interface CostsState {
  estimateCode?: EstimateCode;
  estimateCodeProjectId?: number;
  costsEditModeOn: boolean;
  costsEdits: CostActivityEstimateChange[];
  refetchPending: boolean;
}

export interface SetEstimateCodeAction {
  type: string;
  code: EstimateCode;
  projectId: ProjectId;
}

export interface EstimateCode {
  id: EstimateCodeId;
  description: string;
}

export interface GetEstimateCodesResult {
  estimateCodesForProject: EstimateCode[];
}

export interface ProjectReportPage {
  id: string;
  description: string;
}

export interface ProjectReportWithPages {
  token: string;
  url: string;
  id: string;
  pages: ProjectReportPage[];
  description: string;
}

export interface ProjectReport {
  token: string;
  tokenExpiration: string;
  embedUrl: string;
  dataSetId: string;
  reportId: string;
  description: string;
}

export interface ProjectReportsResponse {
  projectReports: ProjectReport[];
}

export interface ProjectOverviewReportResponse {
  projectOverviewReport: ProjectReport;
}

export type StartCostsEditingAction = {
  type: string;
};

export type EndCostsEditingAction = {
  type: string;
};

export type AddCostsEditAction = {
  type: string;
  edit: CostActivityEstimateChange;
};

export type SetRefetchPendingAction = {
  type: string;
  refetchPending: boolean;
};

export type ProjectCostAction =
  | StartCostsEditingAction
  | EndCostsEditingAction
  | SetEstimateCodeAction
  | AddCostsEditAction
  | SetRefetchPendingAction;

export interface CostComponentChange {
  costComponentId: CostComponentId;
  revisedBudget: number | null;
  currentEstimate: number | null;
  estimatedHours: number | null;
  originalBudget: number | null;
  asSold: number | null;
}

export interface CostSummaryValueDeltas {
  [key: string]: number;

  revisedBudget: number;
  currentEstimate: number;
  estimatedLabor: number;
  asSold: number;
}

export interface CostActivityEstimateChange {
  activityId: ActivityId;
  costComponents: CostComponentChange[];
}

export interface CostCommentChange {
  commentId: string;
  commentText: string;
  commentType: ActivityEstimateCommentTypeId;
  activityId: ActivityId;
}

export interface CostActivityCommentsChange {
  projectId: ProjectId;
  estimateCodeId: string;
  costComments: CostCommentChange[];
}

export interface AddCostCommentResult {
  comment: ProjectCostActivityComment | null;
  errors: string[] | null;
}

export interface DeleteCostCommentResult {
  deleteDateTime: ISOLocalDateTime;
  errors: string[] | null;
}

export interface ProjectCostActivityDataPoint {
  activityId: ActivityId;
  description: string;
  actuals: number;
  committed: number;
}

export enum AccessRight {
  ViewProjectCard = "ViewProjectCard",
  EditProjectCard = "EditProjectCard",
  ViewRecognitions = "ViewRecognitions",
  EditRecognitions = "EditRecognitions",
  ViewCosts = "ViewCosts",
  EditCosts = "EditCosts",
  ViewFinancialComments = "ViewFinancialComments",
  EditFinancialComments = "EditFinancialComments",
}

export interface ProjectAccess {
  isAdmin: boolean;
  isEditor: boolean;
  isProjectController: boolean;
  isProjectManager: boolean;
  hasCostsAccessOnly: boolean;
  accessRights: AccessRight[];
}

export class UserProjectAccess {
  access: ProjectAccess;
  constructor(access: ProjectAccess) {
    this.access = access;
  }

  isAdmin(): boolean {
    return this.access.isAdmin;
  }

  isEditor(): boolean {
    return this.access.isEditor;
  }

  isProjectController(): boolean {
    return this.access.isProjectController;
  }

  isProjectManager(): boolean {
    return this.access.isProjectManager;
  }

  hasCostsAccessOnly(): boolean {
    return this.access.hasCostsAccessOnly;
  }

  has(right: AccessRight): boolean {
    return this.access.accessRights.includes(right);
  }
}

export interface ProjectAccessResult {
  projectAccess: {
    access: ProjectAccess | null;
    error: string | null;
  };
}

export enum LnStatusEnum {
  ReconcilingToLn = "ReconcilingToLn",
  SentToLn = "SentToLn",
  PopProcessing = "PopProcessing",
}

export enum EstimateType {
  AsSold = "AsSold",
  OriginalBudget = "OriginalBudget",
  ProposedBudgetChange = "ProposedBudgetChange",
  RevisedBudget = "RevisedBudget",
  Estimate = "Estimate",
}

export enum EstimateCodeStatusId {
  Draft = "Draft",
  Approved = "Approved",
  Locked = "Locked",
}

export interface ProjectCostEstimateValue {
  estimateCode: EstimateCodeId;
  estimateCodeStatus: EstimateCodeStatusId;
  previousEstimateCode: EstimateCodeId | null;
  isPopProcessing: boolean;
  isSentToLn: boolean;
  isReconcilingToLn: boolean;
  lnInterfaceMessage: string;
  approvedBy: string | null;
  interfaceStatus: number | null;
}

export interface ProjectCostEstimateType {
  estimateType: EstimateType;
  estimateCodes: EstimateCode[];
  estimateValues: ProjectCostEstimateValue[];
}

export interface GetEstimateCodesAndStatesResult {
  estimateCodesAndStatusesForProject: ProjectCostEstimateType[];
}

export interface EstimateCodeSelectionState {
  [key: string]: EstimateCode | undefined;
  asSold: EstimateCode | undefined;
  originalBudget: EstimateCode | undefined;
  proposedBudgetChange: EstimateCode | undefined;
  revisedBudget: EstimateCode | undefined;
  prevEstimate: EstimateCode | undefined;
  currentEstimate: EstimateCode | undefined;
}

export interface ProjectEstimateCodeCostItem {
  estimateCode: EstimateCodeId;
  currencyId: CurrencyId;
  currencyCode: string;
  costItems: ProjectCostItem[];
}

export enum CostCurrencyScenario {
  Lc = "LC",
  LcProject = "LCProject",
  Custom = "Custom",
}

export interface ProjectCostsByCurrencyCode {
  currencyScenario: CostCurrencyScenario;
  currencyId: CurrencyId;
  currencyCode: string;
  costItemsByEstimateCode: ProjectEstimateCodeCostItem[];
  costHeaderMeasures: CostHeaderMeasures;
}

export interface CostHeaderMeasures {
  currencyId: CurrencyId;
  currencyCode: string;
  currencyRate: number;
  asSoldNetSales: number;
  budgetedNetSales: number;
  revisedNetSales: number;
  previousEstimatedNetSales: number;
  currentEstimatedNetSales: number;
  backlog: number;
}

export interface ProjectCostsEditing {
  enabled: boolean;
  disabledReason: string | null;
}

export interface ProjectCostsAndEstimateTypes {
  costEstimationStatus: CostEstimationStatus;
  estimateTypes: ProjectCostEstimateType[];
  costItemsByCurrencyCode: ProjectCostsByCurrencyCode[];
  editing: ProjectCostsEditing;
}

interface EstimateTypeStatus {
  estimateType: EstimateType;
  estimateValue: ProjectCostEstimateValue | undefined;
}

export interface EstimateStatuses {
  [key: string]: EstimateTypeStatus;
  asSold: EstimateTypeStatus;
  originalBudget: EstimateTypeStatus;
  proposedBudgetChange: EstimateTypeStatus;
  revisedBudget: EstimateTypeStatus;
  currentEstimate: EstimateTypeStatus;
}

export interface StatusChangeData {
  projectId: ProjectId;
  estimateCode: EstimateCodeId;
  estimateType: EstimateType;
  status: EstimateCodeStatusId;
  relatedStatuses?: DraftEstimate[];
}

export interface ColumnCopyData {
  projectId: ProjectId;
  estimateType: EstimateType;
  targetEstimateCode: EstimateCodeId;
  estimateCodesAndStatuses: ProjectCostEstimateType;
  column: ProjectCostColumnId;
  currencyScenario: CurrencyScenario;
}

export interface StatusLightValue {
  color: string;
  message: string;
}

export interface NodeMainProjectPairing {
  nodeId: NodeId;
  nodeDesc: string;
  mainProjectId: ProjectId;
}

export interface DraftEstimate {
  estimateType: EstimateType;
  estimateCode: EstimateCodeId;
}

export interface SaveEstimateCodeStatusResult {
  applicationModifiedDateTime: string | null;
  activitiesChanged: ActivityId[];
  error: string | null;
}

export enum NotificationField {
  IsAsSoldMissing = "IsAsSoldMissing",
  IsBudgetMissing = "IsBudgetMissing",
  IsRevisedBudgetMissing = "IsRevisedBudgetMissing",
  IsActualRecognitionGreaterThanEstimatedCosts = "IsActualRecognitionGreaterThanEstimatedCosts",
  IsEstimateDifferentInPOPVsERP = "IsEstimateDifferentInPOPVsERP",
  IsRevisedBudgetDifferentInPOPVsERP = "IsRevisedBudgetDifferentInPOPVsERP",
  IsWarrantyCostEstimateChanged = "IsWarrantyCostEstimateChanged",
}

export interface NotificationMessage {
  notificationField: NotificationField;
  modified: string;
  message: string;
  userCanDismiss: boolean;
}

export enum LogActionType {
  ScheduledLoading = 1,
  CalculationEngineAction = 10,
  CalculationEngineActionInitiatedByUser = 11,
  NewProjectCreated = 100,
  ProjectBasicDataChange = 101,
  ProjectHierarchyChange = 200,
  ProjectRecognitionChange = 300,
  ProjectCostsChange = 400,
}

export interface LogEvent {
  actionTypeId: number;
  logDateTime: string;
  message: string;
  batchRid: number | null;
  executionTimeSeconds: number | null;
  user: string;
}

export interface MonyhlyProjectLogs {
  period: string;
  logs: LogEvent[];
}

export interface YearlyProjectLogs {
  period: string;
  subGroups: MonyhlyProjectLogs[];
}

export interface ProjectLogs {
  projectId: ProjectId;
  grouped: YearlyProjectLogs[];
}

export interface ProjectLogsResult {
  projectLogs: ProjectLogs | null;
}

export interface CurrencyScenario {
  id: CostCurrencyScenario;
  desc: string;
  estimatesCurrencyId: CurrencyId;
  actualsCurrencyId: CurrencyId;
  currencyCode: string;
  editable?: boolean;
}

export enum Status {
  Inactive,
  Active,
}

export enum CostEstimationStatus {
  POPWithLN = "POPWithLN",
  POPWithoutLN = "POPWithoutLN",
  NotInPOP = "NotInPOP",
}

export interface ProjectTagsSectionInput {
  input: ProjectTag[];
  pristine: boolean;
}

export interface ProjectTagsSaveResult {
  result: ProjectTag[];
  applicationModifiedDateTime: ISOTimestamp | null;
  errors: string[] | null;
}

export interface ProjectTag {
  id: string;
  description: string | null;
}

export enum ProjectVirtualType {
  Manual = "Manual",
  LnProject = "LnProject",
  BaanProject = "BaanProject",
  LnSalesOrder = "LnSalesOrder",
  LnServiceOrder = "LnServiceOrder",
  LnServiceContract = "LnServiceContract",
  BaanSalesOrder = "BaanSalesOrder",
  BaanPcsProject = "BaanPcsProject",
  SapSalesOrder = "SapSalesOrder",
  LeanProject = "LeanProject",
  LeanSalesOrder = "LeanSalesOrder",
  EncompixSalesOrder = "EncompixSalesOrder",
  JDEdwardsSalesOrder = "JDEdwardsSalesOrder",
  AggregatedOrders = "AggregatedOrders",
  AutomaticAdjustment = "AutomaticAdjustment",
}

export type CostEditValues = {
  revisedBudget: number | null;
  currentEstimate: number | null;
  estimatedHours: number | null;
  originalBudget: number | null;
  asSold: number | null;
};

export type ProjectCostEditColumnId = keyof CostEditValues;

export type CostComponentEditRow = {
  [key: string]: string | boolean | number | CostEditValues | ProjectCostComponentItem;
  costComponentId: string;
  editValues: CostEditValues;
  initialItem: ProjectCostComponentItem;
  isEditingBlocked: boolean;
  currencyRate: number;
};

export type ProjectCostSummaryRow = {
  [key: string]:
    | string
    | boolean
    | number
    | string[]
    | CostEditValues
    | ProjectCostValues
    | CostComponentEditRow[]
    | undefined;
  id: ActivityId;
  description: string;
  summaryValues: CostEditValues;
  values: ProjectCostValues;
  components?: CostComponentEditRow[];
  isEditingBlocked: boolean;
  currencyRate?: number;
  childLevel: number;
  childItemIds?: string[];
};

export interface SaveHierarchyChangesResponse {
  applicationModifiedDateTime: string | null;
  nodeIds: NodeId[];
  updatedHierarchy: HierarchyItem | null;
  errors: string[] | null;
}
