import { CostCurrencyScenario, CurrencyScenario, ProjectCostEditColumnId } from "../../../../common/types";
import { CostsHeader } from "./types";

export const PROJECT_COST_SUMMARY_HEADERS_SER_ORDER: CostsHeader[] = [
  { id: "asSold", description: "As sold", hasEstimateCode: true },
  { id: "originalBudget", description: "Original budget", hasEstimateCode: true },
  { id: "proposedBudgetChange", description: "Proposed budget change", hasEstimateCode: true },
  { id: "revisedBudget", description: "Revised budget", hasEstimateCode: true },
  { id: "calculatedEstimate", description: "Calculated est.", hasEstimateCode: false },
  { id: "prevEstimate", description: "Previous est.", hasEstimateCode: true },
  { id: "estimateChange", description: "Est. change", hasEstimateCode: true },
  { id: "currentEstimate", description: "Current est.", hasEstimateCode: true },
  { id: "ETC", description: "Etc", hasEstimateCode: true },
  { id: "committed", description: "Committed", hasEstimateCode: false },
  //{ id: "actuals", description: "Actuals", hasEstimateCode: false },
  //{ id: "hardCommitments", description: "Hard comm.", hasEstimateCode: false },
  { id: "wipCosts", description: "WIP costs", hasEstimateCode: false },
  { id: "periodicWipCosts", description: "Periodic WIP costs", hasEstimateCode: true },
  //{ id: "softCommitments", description: "Soft comm.", hasEstimateCode: false },
  { id: "estimatedHours", description: "Est. hours", hasEstimateCode: true },
  { id: "actualHours", description: "Actual hours", hasEstimateCode: false },
];

export const PROJECT_COST_SUMMARY_HEADERS: CostsHeader[] = PROJECT_COST_SUMMARY_HEADERS_SER_ORDER.filter(
  x => x.id !== "calculatedEstimate"
);

export const EDITABLE_COST_COMPONENTS: ProjectCostEditColumnId[] = [
  "revisedBudget",
  "currentEstimate",
  "estimatedHours",
  "asSold",
  "originalBudget",
];

export const DefaultCurrencyScenarios: CurrencyScenario[] = [
  {
    id: CostCurrencyScenario.LcProject,
    desc: "LC Project",
    estimatesCurrencyId: 1,
    actualsCurrencyId: 2,
    currencyCode: "",
    editable: true,
  },
  {
    id: CostCurrencyScenario.Lc,
    desc: "LC",
    estimatesCurrencyId: 1,
    actualsCurrencyId: 1,
    currencyCode: "",
  },
];
